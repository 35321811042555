.cast-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
}

.cast-grid > .cast-member {
  aspect-ratio: 1/1;
  display: grid;
  margin: 1px;
}
