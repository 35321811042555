.table-container {
  grid-area: shelf;
  height: 600px;
  width: 100%;
  padding: 20px;
  margin-bottom: 25px;
}

.table-container .MuiSvgIcon-root {
  color: whitesmoke;
}

.table-container .MuiSelect-select {
  font-size: 12 !important;
}
