.about-container {
  padding: 40px;
  width: 100%;
}

.about-paper-container {
  /* build media queries here */
  display: flex;
  align-items: center;
  max-width: 700px;
  margin: auto;
  margin-top: 0;
  // white-space: pre-wrap;
}

#header {
  font-weight: 600;
}

.supporters-film-beige {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
#meter-paper {
  background-color: rgb(100, 100, 100) !important;
  padding: 10px;
}

.meter-wrapper {
  max-width: 90%;
}

.cast-button-wrapper {
  margin-top: 30px;
}

.about-container h2,
.about-container h3 {
  font-weight: 600;
  margin-top: 10px;
}

.about-container h4 {
  font-weight: 600;
  margin-top: 30px;
}

.about-container a,
.about-container li {
  color: whitesmoke;
}

.about-container a:hover {
  color: rgba(245, 124, 0);
}

.about-container table {
  background-color: rgb(100, 100, 100);
  border-radius: 0.25vh;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

.about-container thead th {
  font-weight: 600;
  font-size: larger;
  padding: 10px;
}

.about-container tr td:first-child {
  font-weight: 600;
}

.about-container tr {
  height: 2em;
}

.about-container tbody tr:nth-child(odd) {
  background-color: rgb(115, 115, 115);
}

.about-container td {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.about-container td ul {
  padding-left: 20px;
}

@media screen and (max-width: 1000px) {
  .about-paper-container {
    width: 95% !important;
  }

  .about-container {
    padding: 20px;
  }
}

.about-container p.first:first-letter {
  float: left;
  font-family: 'Poppins', sans-serif;
  font-size: 75px;
  line-height: 60px;
  padding-top: 2px;
  padding-right: 8px;
  padding-left: 0px;
}

.about-container p:first-of-type:first-line {
  float: left;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
