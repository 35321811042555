.main-footer {
  margin-top: 5vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.main-footer a {
  color: whitesmoke;
}

.main-footer a:hover {
  color: rgba(245, 124, 0);
}

img.footer-ologo:hover {
  filter: sepia(100%) saturate(6000%) contrast(200%) hue-rotate(-50deg);
}

.footer-icons {
  padding-bottom: 5px;
}

.footer-base {
  align-items: flex-end;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 600) {
  .footer-base {
    display: block !important;
    flex-direction: column-reverse !important;
  }
}

.MuiInputBase-input {
  color: whitesmoke;
}

#outlined-required {
  color: whitesmoke;
}
