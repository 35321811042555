.mobile-ad-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.mobile-ad-container .MuiPaper-root {
  background-color: #a27753 !important;
}

.mobile-ad-wrapper {
  position: fixed;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5em;
  // padding-bottom: 1em;
  height: clamp(20px, 50px, 80px);
  align-items: baseline;
}

@media screen and (max-width: 700px) {
  .mobile-ad-wrapper {
    height: auto;
  }
}

.mobile-ad-wrapper p,
.mobile-ad-wrapper a {
  padding: 0 !important;
  font-weight: 500;
  color: #2b2b2b !important;
  text-decoration: none;
}

.mobile-ad-wrapper span {
  color: #645246;
}

.close-icon-mobile {
  // transform: translateY(-1.5pt);
  cursor: pointer;
}

.mobile-ad-wrapper .right p {
  text-align: right;
}
