.navbar-wrapper {
  padding: 0.5rem 0 0.75rem;
  background-color: whitesmoke;
  margin: auto;
}
.navbar-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'title logo menu';
  align-items: center;
  width: clamp(300px, 80%, 100%);
  margin: auto;
}

@media screen and (max-width: 1000px) {
  .navbar-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'logo menu';
  }
  .navbar-logo {
    justify-self: left !important;
  }
}

.navbar-title {
  grid-area: title;
  justify-self: left;
  display: flex;
  align-items: center;
}

.navbar-title h2 {
  font-weight: 500;
  margin-top: 10px;
}

.navbar-logo {
  grid-area: logo;
  justify-self: center;
}

.navbar-socials {
  grid-area: menu;
  justify-self: left;
}

.navbar-menu {
  grid-area: menu;
  justify-self: right;
}

.navtext {
  text-align: right;
}

.navbar-container p,
.navtext a {
  color: #2b2b2b;
  text-decoration: none;
  margin-bottom: 0px !important;
}

.navtext a:hover {
  color: #4c4c4c;
}

.navbar-container h2 {
  color: #2b2b2b !important;
}

.orange {
  color: #f57c00;
}

.orange strong:hover {
  color: #ffc890;
}
