.oscars-film-info .MuiPaper-root {
  background-color: rgb(100, 100, 100) !important;
  width: 100%;
}

.MuiAccordion-root {
  background-color: rgb(100, 100, 100) !important;
}

.director-text {
  font-weight: 600;
}

.oscars-film-info {
  /* build media queries here */
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 0;
  margin-bottom: 2vh;
  // white-space: pre-wrap;
}

.whitewash-info-grid {
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas:
    'header header beige-meter'
    'poster synopsis beige-meter'
    'poster more-info beige-meter';
  column-gap: 40px;
  row-gap: 20px;
  padding: 40px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .oscars-film-info {
    width: 95% !important;
  }
  .whitewash-info-grid {
    position: relative;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 48%);
    grid-template-areas:
      'poster poster'
      'header beige-meter'
      'synopsis synopsis'
      'share share'
      'cast cast'
      'more-info more-info'
      'poster-buttons poster-buttons';
    column-gap: 4%;
    row-gap: 15px;
    padding: 20px;
    width: 100%;
  }

  .poster-buttons {
    grid-area: poster-buttons !important;
  }

  .cast-wrapper {
    grid-area: cast !important;
  }

  .share-buttons {
    grid-area: share;
    margin-bottom: 10px;
  }

  #meter-paper {
    background-color: rgb(130, 130, 130) !important;
  }
  #meter-wrapper {
    width: 100%;
    padding: 5px;
    padding-bottom: 0px;
  }
  #film-title {
    font-weight: 600;
    font-size: 5vw;
  }
  .film-title {
    flex-flow: column nowrap;
    justify-content: flex-start !important;
    gap: 5px;
  }
}

@media screen and (max-width: 500px) {
  .film-beige {
    margin-top: 0;
    justify-self: right;
    max-width: 100% !important;
  }
}

.film-title {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

#film-title {
  font-weight: 600;
}

.film-poster {
  grid-area: poster;
  max-width: 100%;
}

.poster-buttons {
  position: relative;
  grid-area: poster;
  margin-top: 10px;
}

.poster-buttons .MuiButton-root:hover {
  color: #f57c00 !important;
}

.film-synopsis {
  grid-area: synopsis;
}

.cast-wrapper {
  grid-area: beige-meter;
}

.details-table {
  width: 100%;
  border-spacing: 10px;
  justify-content: center;
}

.film-beige {
  grid-area: beige-meter;
  align-self: start;
  width: 100%;
}

#beige-name {
  text-align: center !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}

.beige-assessment {
  grid-area: synopsis;
}

.more-info {
  grid-area: more-info;
}

.more-info .MuiPaper-root {
  background-color: rgb(100, 100, 100) !important;
}

.more-info .MuiSvgIcon-root {
  color: whitesmoke;
}

.beige-assessment p:first-child:first-letter {
  float: left;
  font-family: 'Poppins', sans-serif;
  font-size: 75px;
  line-height: 60px;
  padding-top: 2px;
  padding-right: 8px;
  padding-left: 0px;
}

.beige-assessment p:first-child:first-line {
  float: left;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.beige-assessment p {
  float: none;
  font-family: 'Poppins', sans-serif;
  text-align: justify;
}

.beige-assessment a,
.beige-assessment li {
  color: whitesmoke;
}

.beige-assessment a:hover {
  color: rgba(245, 124, 0);
}

.beige-assessment strong {
  font-weight: 600;
}

.beige-assessment .assessment-image,
.beige-assessment .bagel {
  display: block !important;
  // border: 1px solid #2b2b2b;
  filter: drop-shadow(0, 0, 2, #2b2b2b);
  width: 90%;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.tab {
  margin-left: 2em;
}

.bottom-controls {
  grid-area: controls;
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: center;
  margin: auto;
  margin-top: 0.6em;
  max-width: 50%;
}

.bottom-controls-text {
  padding-top: 0.6em;
  color: rgb(165, 165, 165);
  font-size: 0.9em;
  font-weight: 600;
}

.amplify-image {
  --height: 200px;
  --width: 400px;
}

// Animations
