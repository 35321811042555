.card-flex {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
}

.card-flex .MuiCard-root {
  background-color: rgb(85, 85, 85) !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.card-flex .MuiCardActions-root {
  align-items: flex-end;
}

@media screen and (min-width: 800px) {
  .siang-card {
    // margin-top: 27px;
  }

  .bagel-card {
    margin-top: 226px;
  }
}
