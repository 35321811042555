.press-outer {
  margin-top: 50px;
}

.press-container {
  width: clamp(200px, 60%, 100%);
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
