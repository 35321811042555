:root {
  --spine-transition-duration: 0.1s;
}

.ui-wrapper {
  display: block;
  margin: auto;
  max-width: 90%;
}

.ui-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'top-menu top-menu top-menu controls'
    'shelf shelf shelf shelf'
    'labels labels labels labels';
  padding: 10px;
  padding-bottom: 10px;
  max-width: 100%;
}

@media screen and (max-width: 1000px) {
  .ui-grid {
    grid-template-areas:
      'top-menu top-menu'
      'labels-mob labels-mob'
      'shelf shelf'
      'labels labels'
      'controls controls';
  }
}

.footer {
  min-height: 20vh;
}

/**************************/
/* CSS Animations Classes */
/**************************/

/* 
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
  opacity: 0;
  transform: translate(0, 40px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 200ms ease-out, transform 250ms ease;
}
.fade-exit {
  z-index: -1;
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  z-index: -1;
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 200ms ease-out, transform 250ms ease;
}
