@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.01ch;
  // background: #1a1a1a;
  // overflow-y: overlay;
}

#root {
  background-color: #2b2b2b;
  min-height: 100vh;
  overflow-x: hidden;
}

.MuiPaper-root {
  background-color: rgb(71, 71, 71) !important;
}

p,
li,
table,
h1,
h2,
h3,
h4 {
  color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
}

p {
  hyphens: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  margin-bottom: 5px;
}

h1,
h2,
h3,
h4,
h5,
span {
  display: inline-block;
  color: whitesmoke;
  font-family: 'Poppins', sans-serif;
}

// h2 {
//   font-size: 1.5em;
//   margin-top: 0.83em;
//   margin-bottom: 0.83em;
//   margin-left: 0;
//   margin-right: 0;
//   font-weight: bold;
// }

@media screen and (max-width: 1000px) {
  .desktop-only {
    //
    display: none;
  }
}

@media screen and (min-width: 500px) {
  p,
  li,
  tr {
    font-size: large;
    line-height: 1.5;
  }
}

.inline-text {
  display: inline-block;
}

.noselect {
  cursor: default;
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.main-container {
  margin: auto;
  width: clamp(90%, 90%, 1500px);
  max-width: 2000px;
}

.ui-wrapper {
  display: block;
  margin: auto;
  max-width: 90%;
}
